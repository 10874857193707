import React, { Component } from 'react';

class Showcase extends Component {
    render() {
        return (
            <div className="showcase col-md-12 mb-7 mt-3">
                <div className="row align-items-center justify-content-md-center">
                    <div className={`col-md-4 ${this.props.order ? 'order-md-3' : 'order-md-1'}`}>
                        <img src={this.props.src}  alt="..." width="100%" className="mt-0"/>
                    </div>
                    <div className="col-md-1 order-md-2">
                    </div>
                    <div className={`col-md-7 mt-3 mt-md-0 showcase-body ${this.props.order ? 'order-md-1' : 'order-md-3'}`}>
                        <div className="p-sm-0 mt-sm-4 mt-md-0">
                            <h4 className="mb-0">{this.props.title}</h4>
                            <p className="mt-2 mb-0">
                                {this.props.children}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Showcase;