import React, { Component } from 'react';

class SectionHeader extends Component {
    render() {
        return (
            <div>
                <div className="full-width bg-primary mt-8">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="project-content col col-md-8">
                                <h1 className="text-white my-3">
                                    {this.props.title}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SectionHeader;