import React, { Component } from 'react';
import ExpandedDiv from '../ExpandedDiv/ExpandedDiv';

class Image extends Component {
    render() {
        return (
            <div>
                <ExpandedDiv size={this.props.size}>
                    <img src={this.props.url} style={{"width" : "100%"}} alt=""/>
                </ExpandedDiv>
            </div>
        )
    }
}

export default Image;