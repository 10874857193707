import React from 'react';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { MDXProvider } from '@mdx-js/react';
import Image from '../components/Image/Image';
import '../components/main.scss';
import { Helmet } from 'react-helmet';
import ExpandedDiv from '../components/ExpandedDiv/ExpandedDiv';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import logo from '../../static/favicon.png'
import Showcase from '../components/Showcase/Showcase';

export const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();
  const shortcodes = { Image, ExpandedDiv, SectionHeader, Showcase }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" />
        <link rel="icon" type="image/png" href={logo} sizes="16x16" />
        <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha1/js/bootstrap.min.js" integrity="sha384-oesi62hOLfzrys4LxRF63OJCXdXDipiYWBnvTl9Y9/TRlw5xlKIEHpNyvvDShgf/" crossorigin="anonymous"></script>
      </Helmet>
        {/* <Header siteTitle={title} siteDescription={description} /> */}
      <MDXProvider components={shortcodes}>
        {children}
      </MDXProvider>
    </>
  );
};