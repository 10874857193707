import React, { Component } from 'react';

class ExpandedDiv extends Component {
    render() {
        return (
            <div>
                {
                    {
                        's':    <div className="full-width">
                                    <div className="container">
                                        <div className="row justify-content-md-center">
                                            <div className="project-content col col-md-6">
                                                {this.props.children}
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                        'm':    <div className="full-width">
                                    <div className="container">
                                        <div className="row justify-content-md-center">
                                            <div className="project-content col col-md-8">
                                                {this.props.children}
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                        'l':    <div className="full-width">
                                    <div className="container">
                                        <div className="row justify-content-md-center">
                                            <div className="project-content col col-md-10">
                                                {this.props.children}
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                        'xxl':  <div className="full-width">
                                    {this.props.children}
                                </div>
                    }[this.props.size]
                    ||  this.props.children
                }
            </div>
        )
    }
}

export default ExpandedDiv;